<template>
    <v-card
      flat
      class="pa-3 mt-1"
    >
      <v-card-title class="d-flex align-center justify-center py-0">
        <div
          class="d-flex align-center"
        >
          <v-img
            :src="require(`@/assets/images/logos/logo.png`)"
            max-width="300px"
            alt="logo"
            contain
            class="me-0 "
          ></v-img>
        </div>
      </v-card-title>
      <v-form class="multi-col-validation">
        <v-card-text class="pt-1">
          <p class="text-h6 text-center">Add a marketing message</p>
          <v-row>
            <v-col cols="12" class="py-0 my-1">
              <v-text-field
                v-model="messageTitle"
                ref="message_title"
                outlined dense
                label="Enter Message Title"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0 my-1">
              <v-text-field
                v-model="messageLink"
                ref="message_link"
                outlined dense
                label="Enter Message Link"
              ></v-text-field>
            </v-col>
  
            <v-col cols="12" class="py-0 my-1">
              <v-textarea
                outlined
                v-model="messageContent"
                ref="message_content"
                name="input-7-3"
                label="Enter Message Content"
              ></v-textarea>
            </v-col>
  
            <v-col cols="12" class="py-0 my-1">
              <v-file-input
                show-size
                v-model="messageFile"
                ref="message_file"
                truncate-length="18"
                style="cursor: pointer"
                label="Choose Message File"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
  
        <div class=" text-end">
          <spinner v-if="loading" class="text-end"></spinner>
          <v-btn
            v-else
            color="primary"
            class="me-3 mt-3"
            small
            @click="addMessage()"
          >
            <font-awesome-icon icon="fa-solid fa-plus" class="pe-1" />
            Add Message List
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </template>
  
  <script>
  // eslint-disable-next-line object-curly-newline
  import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
  import axios from 'axios'
  import Spinner from "@/components/Spinner.vue";
  
  export default {
    components: {
      Spinner,
    },

  data() {  
      return {
        loading: false,
        messageTitle: "",
        messageLink: "",
        messageFile: [],
        messageContent: "",
  
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      }
    },
    
    mounted(){
        // executes these after the page has been mounted
        document.title = "TopUp | Add Ad Message"
    },
  
    methods:{  
      async addMessage(){
        console.log(this.messageFile)
        if(this.messageTitle === ""){
            this.$store.commit('setSnackBarMessage', "Please fill in the message name")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.message_title.focus()  // this causes a an auto fucos to the element
        }
        else if(this.messageContent === ""){
            this.$store.commit('setSnackBarMessage', "Please input the message description")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.message_content.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.messageFile){
            this.$store.commit('setSnackBarMessage', "Please upload the message file")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.message_file.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
            let formData = new FormData()
            formData.append('messageFile', this.messageFile)
            formData.append('messageContent', this.messageContent)
            formData.append('messageLink', this.messageLink)
            formData.append('messageTitle', this.messageTitle)
        
            await axios
                .post('/api/v1/topup/add/marketing_message/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    if(response.data[0].id !== 0){
                        console.log('new message:'+response.data[0].id)  // wait after 5000 seconds to redirect user
                        let router = this.$router
                        setTimeout(function(){
                            router.push({ name: 'savings.marketing_prospects'})
                        }, 4000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })

            this.loading = false
        }
      },
    },
  }
  </script>
  